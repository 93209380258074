import styled from "styled-components";
interface PropsStyled {
  opacity: boolean;
}

export const Container = styled.div<PropsStyled>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .screen_market_image {
    margin: auto;
    width: 100%;
  }
  .details_scree_page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    transition: 500ms;
    animation-duration: 3;
    transform: translateX(${(props) => (props.opacity ? "" : "1isOpacity")});
    opacity: ${(props) => (props.opacity ? 0 : 10)};
  }

  @keyframes isOpacity {
    from {
      transform: translateX(-25px);
    }
    to {
      transform: translateX(0);
    }
  }
  .contaienr_title {
    display: none;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 30px;

    justify-content: space-between;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.56),
      rgba(13, 17, 51, 0.56)
    );
    .status_market_on {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      h3 {
        margin-left: 10px;
      }
      .ping_status_market_off {
        width: 25px;
        height: 25px;
        background-color: var(--alert-color);
        border-radius: 50px;
      }
      .ping_status_market_on {
        width: 25px;
        height: 25px;
        background-color: var(--contrast-color);
        border-radius: 50px;
      }
    }
    .logout_button {
      background: transparent;
      border: none;
      width: 30px;
      height: 30px;
      display: flex;
    }

    svg {
      font-size: 25px;
      transition: 1000ms;
    }
    svg:hover {
      color: var(--alert-color);
      font-size: 26px;
    }
  }

  .bottom_screen_market {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background: linear-gradient(
      180deg,
      rgba(13, 17, 51, 0.56),
      rgba(0, 0, 0, 0.56)
    );

    padding: 30px;
    .button_print {
      height: 50px;

      border: none;
      border-radius: 12px;
      background-color: var(--contrast-color);

      color: var(--text-color);

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      font-weight: bold;
      svg {
        margin-right: 10px;
      }
    }
    .button_frame {
      padding: 10px;

      background: var(--contrast-color);
      border: none;
      border-radius: 50px;
      color: var(--text-color);

      display: flex;
      align-items: center;
      justify-content: center;
      transition: 1300ms;
    }
    .button_frame:hover {
      background: transparent;
      color: var(--contrast-color);
      font-size: 18px;

      transform: rotate(3deg);
    }
  }
`;
