import React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import PrivateRoute from "./private_routes";

import ScreenMarketPage from "../../view/screen_market_page";
import LoginPage from "../../view/login_page";

const Routes: React.FC = () => (
  <Router>
  <Switch>
    <Route exact path="/" component={ScreenMarketPage} />
  </Switch>
</Router>
);
export default Routes;
