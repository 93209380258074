import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
.MuiButton-text{
    position:initial;
  }
button.buttonLI{
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
    border:none;
    margin-top:10px;
    border-bottom:1px #c5c5c5 solid;
    background-color:transparent;
    padding-bottom:10px;
    strong{
      margin-right:10px;
    }
    svg{
      font-size:24px;
      color:var(--primary-color)
    }
    :hover{
    background-color:#f1f1f1;
    border-bottom:black solid 1px;
      
    }
  }
  :root {
    --primary-color:#0D1133;
    --secundary-color:#C3C7E5;
    --contrast-color:#36C260;
    --text-color:#f5f5f0;
    --alert-color:#E52E2E;
  }
  *{
    margin:0;
    padding:0;
    outline:0;
    box-sizing: border-box;
  }
  html,body,#root{
    min-height:100%;
  }
  body{
    background:var( --primary-color);
    color:var(--text-color);
    -webkit-font-smoothing:antialiased !important;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1,h2,h3,h4,h5,h6{
    font-family: 'Montserrat', sans-serif;
    font-weight:400;
    color:var(--text-color);
    margin:0;

  }
  h1{
    font-size:50.52px;
  }
  h2{
    font-size:37.9px;
  }
  h3{
    font-size:28.43px;
  }
  h4{
      font-size:21.33px
    }
  h5{
    font-size:16px;
  }

  .pattern_button {
    width:350px;
    height:50px;
    border:none;
    border-radius:12px;
    background-color:var(--contrast-color);
    
    color:var(--text-color);
    font-size:21px;
    font-weight:bold;
    
  }
  .pattern_button:disabled{
    width:350px;
    height:50px;
    border:none;
    border-radius:12px;
    background-color:var( --secundary-color);
    
    color:var(--primary-color);
    font-size:21px;
    font-weight:bold;
  }
`;
