import React, { createContext, useState, useContext, useCallback } from "react";
import jwt from "jsonwebtoken";

import api from "../../main/services/api";

interface InfoLogin {
  email: string;
  password: string;
}
interface Authorized {
  isAuthorized: boolean;
  token: string | null;
}
interface AuthContextData {
  token: Authorized;
  error: string;
  signIn(data: InfoLogin): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [errorLogin, setErrorLogin] = useState<string>("");
  const [token, setToken] = useState<Authorized>(() => {
    const token = localStorage.getItem("@GC:token");

    if (token) {
      try {
        jwt.verify(token!, `${process.env.REACT_APP_AUTH}`);
        api.defaults.headers.Authorization = `Bearer ${token}`;
        return { isAuthorized: true, token };
      } catch (err) {
        localStorage.clear();
        api.defaults.headers.Authorization = "Bearer ";
        return { isAuthorized: false, token: null };
      }
    }
    return {} as Authorized;
  });

  const signIn = useCallback(async (dataSingin: any) => {
    try {
      await api
        .post(`${process.env.REACT_APP_API_URL}/login`, dataSingin)
        .then((response) => {
          if (response.status === 200) {
            const token = response.data.accessToken;
            localStorage.setItem("@GC:token", token.toString());
            api.defaults.headers.Authorization = `Bearer ${token}`;
            setToken({ isAuthorized: true, token });
          }

          if (response.status === 203) {
            return setErrorLogin("Usuario não encontrado");
          }
          if (response.status === 401) {
            return setErrorLogin("Senha Invalida");
          }
        });
    } catch {
      return setErrorLogin("Não foi possivel concluir sua solicitação");
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.clear();
    setToken({ isAuthorized: false, token: null });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        error: errorLogin,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
