import Routes from "./main/routes/routes";
import GlobalStyled from "../src/main/styles/global_styles";
import { AuthProvider } from "./controller/auth_provaider";

function App() {
  return (
    <AuthProvider>
      <Routes />
      <GlobalStyled />
    </AuthProvider>
  );
}

export default App;
