export class ScreenMarketModel {
    path: string
    info: string
    time: number

    constructor(path: string, info: string, time: number) {
        this.path = path
        this.info = info
        this.time = time
    }
}