import db from "../../main/firebase_config";
import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import { FaCamera, FaSignOutAlt } from "react-icons/fa";
import { useAuth } from "../../controller/auth_provaider";

import {
  query,
  orderBy,
  limit,
  onSnapshot,
  collection,
} from "firebase/firestore";
import { ScreenMarketModel } from "../../model/screen_market_model";
import { downloadScreenMarket } from "../../controller/download_screen_market";
import { isOfflineMarketScreen } from "../../controller/is_offline_market_screen";

const ScreenMarketPage: React.FC = () => {
  const { signOut } = useAuth();
  const [screenMarket, setScreenMarket] = useState<ScreenMarketModel>();
  const [isOfflineServer, setIsOfflineServer] = useState<boolean>(true);
  const [mouseIsStopped, SetMouseIsStopped] = useState<boolean>(false);

  useEffect(() => {
    const getLatestScreenMarket = query(
      collection(db, "tela-mercado"),
      orderBy("time", "desc"),
      limit(1)
    );

    onSnapshot(getLatestScreenMarket, (documents) => {
      const [getDocument] = documents.docChanges();
      const { path, info, time } = getDocument.doc.data();

      setScreenMarket(new ScreenMarketModel(path, info, time));
      setIsOfflineServer(isOfflineMarketScreen(time));
    });
  }, []);

  return (
    <Container opacity={mouseIsStopped}>
      <img
        className="screen_market_image"
        src={`data:image/png;base64,${screenMarket?.path}`}
        alt=""
      />

      <div
        className="details_scree_page"
        onClick={() => {
          SetMouseIsStopped(!mouseIsStopped);
        }}
      >
        <div className="contaienr_title">
          <div className={"status_market_on"}>
            <div
              className={
                isOfflineServer
                  ? "ping_status_market_off"
                  : "ping_status_market_on"
              }
            ></div>
            <h3>{isOfflineServer ? "offline" : "online"}</h3>
          </div>
          <h1>Tela de Chicago</h1>
          <a href="/" onClick={() => signOut()}>
            <FaSignOutAlt />
          </a>
        </div>

        <div className="bottom_screen_market">
          <button
            className="button_print"
            onClick={() => downloadScreenMarket(screenMarket)}
          >
            <FaCamera />
            salvar tela
          </button>
          <p>{screenMarket?.info}</p>
        </div>
      </div>
    </Container>
  );
};

export default ScreenMarketPage;
